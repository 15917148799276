import React from "react";
import "./App.css";

function App() {
  return (
    <div className="app">
      <div className="title">CURTIS LIU</div>
      <div className="nav">
        <ul className="links">
          <li>
            <a href="http://www.amplitude.com">
              <img src="images/amplitude.svg" alt="Amplitude" />
              Amplitude
            </a>
          </li>
          <li>
            <a href="http://www.puzzlepotluck.com">
              <img src="images/puzzlepotluck.png" alt="Puzzle Potluck" />
              Puzzle Potluck
            </a>
          </li>
          <li style={{ marginTop: "16px" }}>
            <a href="http://www.linkedin.com/in/curtisliu">
              <img src="images/linkedin.svg" alt="LinkedIn" />
            </a>
          </li>
        </ul>
      </div>
      <div className="content"></div>
    </div>
  );
}

export default App;
